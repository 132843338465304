import React, { Component } from 'react'
import { BarChart, Bar, Cell, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';

class graphMarker extends Component {
 
    constructor(props) {
        super(props)
        this.state = {
             dataload: 0,
             some: '' ,
             tickBegin: 0,
             tickEnd: 0,
             ticksAmount: props.ticks,
             ticksStart: props.ticksStart,
             tickdiv: props.tickdiv,
             unitid: props.unitid,
             dataid: props.dataid
        }

        console.log( props.urlS )
        //this.urls = "http://192.168.1.17:3000/getunitdata?unitid=" + props.unitid +  "&dataid=" + props.dataid + "&ticks=" + props.ticks 

    }
    componentDidMount() {
        this.fetchData();
        this.timeInterval = setInterval(() => {
          this.fetchData();
          console.log("Timer")
        },60000)
    }
    componentWillUnmount(){
        clearInterval(this.timeInterval)
    }

    CallFromParent(updateId,ticksInput){
      console.log("CallFrom")
      console.log(ticksInput)
      if(updateId == 1 ){
          this.state.ticksAmount = ticksInput;
      }
      if(updateId == 2 ){
          this.state.ticksStart = ticksInput;
      }
      if(updateId == 3 ){
        this.state.tickdiv = ticksInput;
      }
      if(updateId == 4){
        this.state.ticksStart = this.state.tickBegin;
    }
    if(updateId == 5){
        this.state.ticksStart = this.state.tickEnd + this.state.ticksAmount;
    }
      this.fetchData();
    }

    refreshData(){
      this.fetchData();
    }
    
    fetchData = function(){
        //this.urls = "http://192.168.1.17:3000/getunitdata?unitid=" + this.props.unitid 
        this.urls = "https://sdlab.dk/getunitdata?unitid=" + this.props.unitid 
          +  "&dataid=" + this.props.dataid 
          + "&ticks=" + this.state.ticksAmount 
          + "&tickdiv=" + this.state.tickdiv 
          + "&ticksStart=" + this.state.ticksStart
        return fetch(this.urls)
        .then((response) => response.json())
        .then((data) => {
          console.log(data);
          console.log('BBBBB');
          console.log(data);
          this.state.tickEnd = data[0].tick;
          this.data = data.reverse();
          this.state.tickBegin = data[0].tick;
          //this.data = dataTest.reverse();
          this.setState({
                dataload: 1
            }
          )
        });
      }

      
    
    render() {
        return (
          <BarChart 
            wrapperStyle={{ zIndex: 10}}
            width={752}
            height={10}
            data={this.data}
            margin={{
                top: 0,
                right: 26,
                left: 0,
                bottom: 0
                }}
            barCategoryGap={100}
            >
                <CartesianGrid vertical={false} stroke='#EAF0F4'/>
                <Bar dataKey="data" fill="#ffffff" stroke="#dad5d1"  />
          </BarChart>
          
        )
    }
    
}
export default graphMarker