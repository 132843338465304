import React, { Component } from 'react'
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';

class graph extends Component {
    
    constructor(props) {
        super(props)
        
        this.state = {
             dataload: 0,
             some: '',
             tickBegin: 0,
             tickEnd: 0,
             ticksAmount: props.ticks,
             ticksStart: props.ticksStart,
             tickdiv: props.tickdiv,
             unitid: props.unitid,
             dataid: props.dataid
        }
        console.log( props.urlS )
       // this.urls = "https://192.168.1.17:3000/getunitdata?unitid=" + props.unitid +  "&dataid=" + props.dataid + "&ticks=" + props.ticks
       
    }
    componentDidMount() {
        this.fetchData();
        this.timeInterval = setInterval(() => {
            this.fetchData();
            console.log("Timer")
          },60000)
      }
      componentWillUnmount(){
          clearInterval(this.timeInterval)
      }

      CallFromParent(updateId,ticksInput){
        console.log("CallFrom")
        console.log(ticksInput)
        if(updateId == 1 ){
            this.state.ticksAmount = ticksInput;
        }
        if(updateId == 2 ){
            this.state.ticksStart = ticksInput;
        }
        if(updateId == 3 ){
            this.state.tickdiv = ticksInput;
        }
        if(updateId == 4){
            this.state.ticksStart = this.state.tickBegin;
        }
        if(updateId == 5){
            this.state.ticksStart = this.state.tickEnd + this.state.ticksAmount;
        }
        this.fetchData();
      }

    fetchData = function(){
        //             http://192.168.1.17:3000/getunitdata?unitid=1000&dataid=4&ticks=6&tickdiv=2&ticksStart=38000
        //this.urls = "http://192.168.1.17:3000/getunitdata?unitid=" + this.state.unitid +  "&dataid=" + this.state.dataid + "&ticks=" + this.state.tickCount
        this.urls = "https://sdlab.dk/getunitdata?unitid=" + this.props.unitid 
            +  "&dataid=" + this.props.dataid 
            + "&ticks=" + this.state.ticksAmount 
            + "&tickdiv=" + this.state.tickdiv
            + "&ticksStart=" + this.state.ticksStart

        return fetch(this.urls)
        .then((response) => response.json())
        .then((data) => {
          console.log(data);
          console.log('BBBBB');
          console.log(data);
          this.state.tickEnd = data[0].tick;
          this.data = data.reverse();
          this.state.tickBegin = data[0].tick;
          this.setState({
                dataload: 1
            }
          )
        });
      }

      
    
    render() {
        return (
            <div>
                <div>
                    <LineChart
                    width={800}
                    height={140}
                    data={this.data}
                    margin={{
                    top: 20,
                    right: 20,
                    left: 0,
                    bottom: 20
                    }}
                >
                    
                    <CartesianGrid vertical={false}  stroke='#EAF0F4' />
                    <XAxis dataKey="tick" tick={{fontSize: 6}} stroke="#474440" />
                    <YAxis tickCount={10} tick={{fontSize: 8}}  domain={[0, 500]} interval={1} stroke="#474440"  />
                    <Tooltip wrapperStyle={{ fontSize: 10 }}/>
                    <Line type="monotone" dataKey="data" stroke="#474440"  dot={{ r: 1 }}  activeDot={{ r: 4 }}  />
                </LineChart>

                </div>
            </div>
        )
    }
}

export default graph
