import React, {Component} from 'react';
import Counter from './components/counter';
import Graph from './components/graph';
import Graph2 from './components/graph2';
import Graph4 from './components/graph4';
import Graph5 from './components/graph5';
import GraphMarker from './components/graphMarker';
import Menu from './components/menu';
import { history } from 'history';
import { Link } from 'react-router-dom';
import './App.css';
import './Menu.css';

import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
//import Header from './components/header'

class App extends Component {
  
  constructor(props){
    super(props);
    this.myRef1 = React.createRef()
    this.myRef2 = React.createRef()
    this.myRef3 = React.createRef()
    //this.myRef4 = React.createRef()
    this.refState1 = React.createRef()
    this.refState2 = React.createRef()
    this.refState3 = React.createRef()
    this.refState4 = React.createRef()
    this.refState5 = React.createRef()
    this.refState6 = React.createRef()
    this.refState7 = React.createRef()
    this.menu = React.createRef()
  }

  accessChildSet (updateId, tickAmounA){
    console.log('Test accessChild')
    console.log('sss')
    this.myRef1.current.CallFromParent(updateId, tickAmounA)
    this.myRef2.current.CallFromParent(updateId, tickAmounA)
    this.myRef3.current.CallFromParent(updateId, tickAmounA)
    //this.myRef4.current.CallFromParent(updateId, tickAmounA)
    this.refState1.current.CallFromParent(updateId, tickAmounA)
    this.refState2.current.CallFromParent(updateId, tickAmounA)
    this.refState3.current.CallFromParent(updateId, tickAmounA)
    this.refState4.current.CallFromParent(updateId, tickAmounA)
    this.refState5.current.CallFromParent(updateId, tickAmounA)
    this.refState6.current.CallFromParent(updateId, tickAmounA)
    this.refState7.current.CallFromParent(updateId, tickAmounA)
  }
  

  accessChildSetTickstart(tickStart){
    console.log('Test tickStart')

  }

  render() {
  console.log('Test')

  return (
   
  <Router  >
      <div className="App">
          <div className="inner-div"> 
            <div>..</div>
            <Switch> 
            <Route path="/home">
              <div>
                Home
              </div>
            </Route>
            <Route path="/unit">
            <div className="controlbox1" >
              <div className="controlbox2" >
                  <h1>Option</h1>
              </div>
              <div className="controlbox3">
                 <div> <h4>Number of tick:&nbsp;</h4></div>
                 <button className="button-13" onClick={ () => {this.accessChildSet(1,50)} }>50</button>
                 <button className="button-13" onClick={ () => {this.accessChildSet(1,100)} }>100</button>
                 <button className="button-13" onClick={ () => {this.accessChildSet(1,200)} }>200</button>
                 <button className="button-13" onClick={ () => {this.accessChildSet(1,300)} }>300</button>
                 <div><h4>&nbsp;Go to tick:&nbsp;</h4></div>
                 <button className="button-13" onClick={ () => {this.accessChildSet(2,0)} }>Newest</button>
                 <button className="button-13" onClick={ () => {this.accessChildSet(2,35676)} }>35676</button>
                 <div><h4>&nbsp;Tick Devider:&nbsp;</h4></div>
                 <button className="button-13" onClick={ () => {this.accessChildSet(3,0)} }>0</button>
                 <button className="button-13" onClick={ () => {this.accessChildSet(3,2)} }>2</button>
                 <button className="button-13" onClick={ () => {this.accessChildSet(3,5)} }>5</button>
                 <button className="button-13" onClick={ () => {this.accessChildSet(3,10)} }>10</button>
                 <button className="button-13" onClick={ () => {this.accessChildSet(3,60)} }>60</button>
                 <div><h4>&nbsp;Jump.&nbsp;</h4></div>
                 <button className="button-13" onClick={ () => {this.accessChildSet(4,0)} }>Back</button>
                 <button className="button-13" onClick={ () => {this.accessChildSet(5,0)} }>Forward</button>
                 </div>
              </div>
            <div className="GraphMarkerBox1"  >
              <div className="box2" >
              <div className="GraphMarkerTextContainer" >
                <h3>NORMAL</h3>
                <h3>BALANCING</h3>
                <h3>FULL</h3>
                <h3>EMTY CHARGING OFF</h3>
                <h3>EMTY CHARGING ON</h3>
                <h3>OUTPUT ON</h3>
                <h3>SLEEP</h3>
              </div>
              <h1>States</h1>
              
             
              </div>
              <div className="GraphMarkerContainer" >
                   <GraphMarker ref={this.refState1} unitid='1000' dataid='6' ticks='100' tickdiv="0" ticksStart='0'/>
                   <GraphMarker ref={this.refState2} unitid='1000' dataid='7' ticks='100' tickdiv="0" ticksStart='0'/>
                   <GraphMarker ref={this.refState3} unitid='1000' dataid='8' ticks='100' tickdiv="0" ticksStart='0'/>
                   <GraphMarker ref={this.refState4} unitid='1000' dataid='9' ticks='100' tickdiv="0" ticksStart='0'/>
                   <GraphMarker ref={this.refState5} unitid='1000' dataid='10' ticks='100' tickdiv="0" ticksStart='0'/>
                   <GraphMarker ref={this.refState6} unitid='1000' dataid='11' ticks='100' tickdiv="0" ticksStart='0'/>
                   <GraphMarker ref={this.refState7} unitid='1000' dataid='12' ticks='100' tickdiv="0" ticksStart='0'/>
             </div> 
       
            </div>
            <div className="box1"  >
                <div className="box2" >
                <h1>Bat Voltage</h1>
                <h2>[mV]</h2>
                </div>
                <div className="box3">
                  <Graph ref={this.myRef1} unitid='1000' dataid='15' ticks='100' tickdiv="0" ticksStart='0' />
                </div>
            </div>
            <div className="box1"  >
                <div className="box2" >
                  <h1>Battery Voltage</h1>
                  <h2>[mV]</h2>
                </div>
                <div className="box3">
                 <Graph4 ref={this.myRef2} unitid='1000' dataidA='18' dataidB='19' dataidC='20' dataidD='21' ticks='100' tickdiv="0" ticksStart='0' AName='Cell1' BName='Cell2' CName='Cell3' DName='Cell4'  />
               </div>
            </div>
            <div className="box1"  >
                <div className="box2" >
                  <h1>Input Amp</h1>
                  <h2>[mA]</h2>
                </div>
                <div className="box3">
                <Graph2 ref={this.myRef3} unitid='1000' dataidA='14' dataidB='16' ticks='100' tickdiv="0" ticksStart='0' AName='Input C' BName='Output C' EName='Total'  />
               </div>
            </div>
            </Route>
            <Route path="/contact">
              <div>
                Contact
              </div>
              <div className="box1"  >
                <div className="box2" >
                  <h1>Input Output Voltage</h1>
                  <h2>[mV] [mA]</h2>
                </div>
                <div className="box3">
                 <Graph5 ref={this.myRef4} unitid='1000' dataidA='13' dataidB='14' dataidC='15' dataidD='16' dataidE='0' ticks='100' tickdiv="0" ticksStart='0' AName='Input V' BName='Input C' CName='Output V' DName='Output C' EName=''  />
               </div>
            </div>
            </Route>
          </Switch>
            <div className="menu" >
              <Menu />
            </div>
          </div>
      </div>
    </Router>
  );
}
}
export default App;
