import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';

export class menu extends Component {
  


    saySomething(something) {
        console.log(something);
    }
	
	

    handleClick(e) {
        this.saySomething("element clicked");
		this.saySomething(e);
	
    }
	handleClickHome(e) {
        this.saySomething("Home");
		//this.setState({redirect: true});
		//this.history.push("/some/Path");
		//this.props.history.push("./Home");
    }

	handleClickKontakt(e) {
        this.saySomething("Kontakt");
		//window.location = "./kontakt";
    }

	handleClickUnit(e) {
        this.saySomething("Unit");
		//window.location = "./unit";
    }

    componentDidMount() {
        this.saySomething("component did mount");
    }
    
    
    render() {
        return (

			   <div className="menuMaina">
              <div id="m" className="menuContainer"> 
                    <div  className="menuLogo" >
                            <svg  viewBox="0 0 400 200" width="400" height="200" >
                                    
                            <path fill="#ffffff"  d="M281.41,122.29c0.6,0,1.03,0.24,1.3,0.71c0.26,0.47,0.4,0.9,0.4,1.27c-1.2,2.97-2.26,5.68-3.19,8.13
			c-0.4,1.04-0.8,2.06-1.2,3.08c-0.4,1.01-0.75,1.95-1.05,2.79c-0.3,0.85-0.53,1.57-0.7,2.16c-0.17,0.59-0.25,0.98-0.25,1.17
			c0,0.33-0.08,0.87-0.25,1.63c-0.17,0.76-0.17,1.45,0,2.09c0.16,0.64,0.6,1.06,1.3,1.27c0.7,0.21,1.91-0.08,3.64-0.88
			c0.13-0.24,0.56-0.54,1.3-0.92c0.73-0.38,1.53-0.85,2.39-1.41c0.53-0.38,0.86-0.77,1-1.17c0.13-0.4,0.33-0.6,0.6-0.6
			c0-0.38,0.23-0.73,0.7-1.06c0.46-0.33,0.7-0.64,0.7-0.92c0.13-0.33,0.5-0.94,1.1-1.84c0.6-0.9,1.26-1.98,2-3.25
			c0.6-0.9,1.16-1.86,1.7-2.9c0.53-1.04,1-2.05,1.4-3.04c0.4-0.99,0.71-1.9,0.95-2.72c0.23-0.82,0.35-1.5,0.35-2.02
			c0-0.52,0.16-0.92,0.5-1.2c0.33-0.28,0.75-0.5,1.25-0.64c0.5-0.14,1.01-0.22,1.55-0.25c0.53-0.02,1-0.04,1.4-0.04h2.29
			c0.8,0,1.45,0.28,1.95,0.85c0.5,0.57,0.75,1.2,0.75,1.91c0,0.14-0.08,0.58-0.25,1.31c-0.17,0.73-0.37,1.57-0.6,2.51
			c-0.23,0.94-0.52,1.91-0.85,2.9c-0.33,0.99-0.67,1.82-1,2.48c-1.4,2.12-2.76,4.23-4.09,6.33c-1.33,2.1-1.99,4.14-1.99,6.12
			c0,0.24,0.13,0.5,0.4,0.78c0.13,0.14,0.27,0.26,0.4,0.35c0.86,0,1.75-0.19,2.64-0.57c0.9-0.38,1.7-0.78,2.39-1.2
			c0.7-0.42,1.31-0.82,1.84-1.2c0.53-0.38,0.9-0.57,1.1-0.57c0.2,0,0.41,0.07,0.65,0.21c0.23,0.14,0.45,0.28,0.65,0.42
			c0.26,0.19,0.5,0.38,0.7,0.57c0,0.19-0.32,0.65-0.95,1.38c-0.63,0.73-1.36,1.51-2.19,2.33c-0.83,0.83-1.63,1.59-2.39,2.3
			c-0.76,0.71-1.25,1.13-1.45,1.27c-0.66,0.33-1.25,0.63-1.74,0.88c-0.5,0.26-0.95,0.52-1.35,0.78c-0.4,0.26-0.82,0.57-1.25,0.92
			c-0.43,0.35-0.91,0.79-1.45,1.31c-0.87,0.61-1.76,1.04-2.69,1.27c-0.93,0.24-1.9,0.35-2.89,0.35c-0.47,0-0.92-0.11-1.35-0.32
			c-0.43-0.21-0.82-0.47-1.15-0.78c-0.33-0.31-0.6-0.64-0.8-0.99c-0.2-0.35-0.3-0.65-0.3-0.88v-1.2v-0.78
			c0.26-0.57,0.53-1.13,0.8-1.7c0.2-0.47,0.4-0.97,0.6-1.49c0.2-0.52,0.3-0.97,0.3-1.34c0-0.28-0.05-0.58-0.15-0.88
			c-0.1-0.31-0.42-0.46-0.95-0.46c-0.4,0-0.87,0.18-1.4,0.53c-0.53,0.35-1.06,0.76-1.6,1.2c-0.53,0.45-1.03,0.88-1.5,1.31
			c-0.47,0.42-0.83,0.71-1.1,0.85c-0.4,0.38-1.13,0.96-2.19,1.73c-1.06,0.78-2.19,1.56-3.39,2.33s-2.34,1.47-3.44,2.09
			c-1.1,0.61-1.88,0.92-2.34,0.92c-0.4,0-0.8-0.13-1.2-0.39c-0.4-0.26-0.75-0.57-1.05-0.92c-0.3-0.35-0.55-0.71-0.75-1.06
			c-0.2-0.35-0.3-0.6-0.3-0.74v-1.98c0-0.38,0.03-0.75,0.1-1.13c0.06-0.33,0.12-0.66,0.15-0.99c0.03-0.33,0.05-0.61,0.05-0.85
			c0-0.28,0.08-0.67,0.25-1.17c0.16-0.5,0.25-1.07,0.25-1.73c0-0.61,0.12-1.25,0.35-1.91c0.23-0.66,0.41-1.15,0.55-1.49l0.8-2.69
			c0.13-0.33,0.48-0.9,1.05-1.7c0.56-0.8,0.85-1.89,0.85-3.25c0-0.66,0.03-1.12,0.1-1.38c0.07-0.26-0.33-0.39-1.2-0.39
			c-0.4,0-0.82,0.05-1.25,0.14c-0.43,0.1-1.01,0.24-1.75,0.42c-0.53,0.24-1.06,0.42-1.6,0.57c-0.53,0.14-0.93,0.21-1.2,0.21
			c-0.4,0-0.83-0.18-1.3-0.53c-0.47-0.35-0.7-0.81-0.7-1.38c0-0.57,0.2-1,0.6-1.31c0.4-0.31,0.88-0.57,1.45-0.78
			c0.56-0.21,1.11-0.42,1.65-0.64c0.53-0.21,0.9-0.48,1.1-0.81c0.86-0.47,1.9-1.14,3.09-2.02c1.2-0.87,2.43-1.73,3.69-2.58
			c1.26-0.85,2.48-1.58,3.64-2.19c1.16-0.61,2.21-0.92,3.14-0.92H281.41z"/>                
                                    <path fill="#ffffff"  d="M133.23,96.79c0-19.99,5.85-35.55,17.54-46.68c11.7-11.13,27.98-16.7,48.86-16.7c21.4,0,37.89,5.47,49.47,16.41
			c11.58,10.94,17.37,26.26,17.37,45.97c0,14.31-2.53,26.04-7.59,35.2c-5.06,9.16-12.37,16.28-21.93,21.38
			c-9.56,5.09-21.48,7.64-35.74,7.64c-14.5,0-26.5-2.2-36.01-6.6c-9.5-4.4-17.21-11.36-23.11-20.88
			C136.18,123.01,133.23,111.1,133.23,96.79z M172.96,96.96c0,12.36,2.41,21.24,7.24,26.64c4.82,5.4,11.39,8.1,19.69,8.1
			c8.54,0,15.14-2.64,19.82-7.93c4.68-5.29,7.02-14.78,7.02-28.48c0-11.52-2.44-19.95-7.32-25.26c-4.88-5.32-11.51-7.98-19.87-7.98
			c-8.01,0-14.44,2.7-19.3,8.1C175.39,75.55,172.96,84.49,172.96,96.96z"/>
                    <path fill="#ffffff"  d="M330.78,111.33c0.6,0,1.18,0.01,1.75,0.04c0.56,0.02,1.1,0.1,1.6,0.21c0.5,0.12,0.9,0.31,1.2,0.57
			c0.3,0.26,0.45,0.63,0.45,1.1c0,0.28-0.47,0.81-1.4,1.59c-0.93,0.78-1.95,1.63-3.04,2.55c-1.1,0.92-2.11,1.82-3.04,2.69
			c-0.93,0.87-1.4,1.55-1.4,2.02c0,0.38,0.15,0.66,0.45,0.85c0.3,0.19,0.65,0.32,1.05,0.39c0.4,0.07,0.81,0.12,1.25,0.14
			c0.43,0.02,0.75,0.04,0.95,0.04h3.29c0.13-0.09,0.33-0.21,0.6-0.35c0.53-0.28,0.96-0.42,1.3-0.42c1,0,1.71,0.28,2.14,0.85
			c0.43,0.57,0.65,1.2,0.65,1.91v0.57v1.34c0,0.52-0.4,0.92-1.2,1.2s-1.68,0.5-2.64,0.64c-0.96,0.14-1.9,0.24-2.79,0.28
			c-0.9,0.05-1.45,0.07-1.65,0.07c-1,0-1.56-0.04-1.7-0.11c-0.13-0.07-0.4-0.11-0.8-0.11h-2c-0.4,0-0.96,0.14-1.7,0.42
			c-0.73,0.28-1.1,0.61-1.1,0.99c0,0.52-0.03,0.98-0.1,1.38c-0.07,0.4-0.13,0.74-0.2,1.03c-0.07,0.33-0.13,0.64-0.2,0.92
			c-0.2,0.28-0.4,0.54-0.6,0.78c-0.33,0.47-0.5,0.87-0.5,1.2v0.78c0,0.8-0.17,1.67-0.5,2.62c-0.33,0.94-0.63,1.51-0.9,1.7
			c-0.33,0.24-0.6,0.46-0.8,0.67c-0.2,0.21-0.3,0.77-0.3,1.66v1.41c0,0.28,0.06,0.63,0.2,1.03c0.13,0.4,0.36,0.77,0.7,1.1
			c0.33,0.33,0.73,0.61,1.2,0.85c0.46,0.24,1.06,0.35,1.8,0.35c0.86,0,1.6-0.16,2.19-0.5c0.26-0.14,0.53-0.31,0.8-0.5
			c0.53,0,0.95,0.11,1.25,0.32c0.3,0.21,0.58,0.44,0.85,0.67c0.26,0.28,0.5,0.61,0.7,0.99c0,0.38-0.22,0.87-0.65,1.49
			c-0.43,0.61-0.95,1.21-1.55,1.8c-0.6,0.59-1.23,1.11-1.9,1.56c-0.67,0.45-1.23,0.67-1.7,0.67c-0.47,0-0.91,0.27-1.35,0.81
			c-0.43,0.54-0.65,0.93-0.65,1.17c-0.2,0-0.57,0.05-1.1,0.14c-0.53,0.09-1.06,0.19-1.6,0.28c-0.67,0.14-1.33,0.26-2,0.35
			c-0.86,0-1.55-0.04-2.04-0.11c-0.5-0.07-0.75-0.3-0.75-0.67c-0.2-0.1-0.5-0.44-0.9-1.03c-0.4-0.59-0.8-1.24-1.2-1.95
			c-0.4-0.71-0.75-1.4-1.05-2.09c-0.3-0.68-0.45-1.17-0.45-1.45v-3.32c0-0.19,0.08-0.62,0.25-1.31c0.16-0.68,0.36-1.44,0.6-2.26
			c0.23-0.82,0.43-1.65,0.6-2.48c0.17-0.82,0.25-1.5,0.25-2.02v-1.2c0.53-1.08,1.03-2.05,1.5-2.9c0.33-0.75,0.66-1.45,1-2.09
			c0.33-0.64,0.5-1,0.5-1.1v-2.19c0-0.61-0.27-0.99-0.8-1.13c-0.4-0.24-0.83-0.47-1.3-0.71c-0.47-0.24-0.7-0.54-0.7-0.92
			c0-0.28,0.15-0.58,0.45-0.88c0.3-0.31,0.66-0.62,1.1-0.96c0.43-0.33,0.86-0.67,1.3-1.03c0.43-0.35,0.78-0.7,1.05-1.03
			c0.46-0.71,0.91-1.14,1.35-1.31c0.43-0.16,0.91-0.39,1.45-0.67c0.86-0.71,1.8-1.3,2.79-1.77c1-0.47,1.93-1.2,2.79-2.19
			c0.53-0.66,0.93-1.32,1.2-1.98c0.26-0.66,0.86-1.18,1.8-1.56c0.86-0.19,1.63-0.55,2.29-1.1
			C329.85,111.6,330.38,111.33,330.78,111.33z"/>
		<path fill="#ffffff" d="M358.51,121.3c1,0,1.9,0.33,2.69,0.99c0.8,0.66,1.2,1.32,1.2,1.98c0,0.52-0.53,0.96-1.6,1.31
			c-1.06,0.35-2.26,0.68-3.59,0.99c-1.33,0.31-2.64,0.6-3.94,0.88c-1.3,0.28-2.14,0.59-2.54,0.92c-0.6,0.42-1.03,0.91-1.3,1.45
			c-0.27,0.54-0.4,1.1-0.4,1.66c0,0.57,0.13,1.11,0.4,1.63c0.26,0.52,0.6,0.99,1,1.41c0.46,0.66,1.01,1.36,1.65,2.09
			c0.63,0.73,1.21,1.57,1.75,2.51c0.53,0.94,0.98,2.01,1.35,3.18c0.36,1.18,0.55,2.5,0.55,3.96c0,1.27-0.6,2.56-1.8,3.86
			c-1.2,1.3-2.73,2.44-4.59,3.43c-1.46,0.57-2.59,1.03-3.39,1.38c-0.8,0.35-1.5,0.64-2.09,0.85c-0.6,0.21-1.18,0.35-1.75,0.42
			c-0.57,0.07-1.28,0.11-2.14,0.11h-3.59c-0.33,0-0.76,0.02-1.3,0.07c-0.53,0.05-1.06,0.12-1.6,0.21c-0.67,0.09-1.36,0.19-2.09,0.28
			h-0.6h-2.19c-1.86,0-2.79-0.64-2.79-1.91c0-0.33,0.3-0.85,0.9-1.56c0.6-0.71,1.35-1.39,2.24-2.05c0.9-0.66,1.85-1.25,2.84-1.77
			c1-0.52,1.86-0.78,2.59-0.78c0.2,0,0.53,0.11,1,0.32c0.46,0.21,1.01,0.44,1.65,0.67c0.63,0.24,1.35,0.46,2.14,0.67
			c0.8,0.21,1.7,0.32,2.69,0.32c0.66,0,1.28-0.14,1.84-0.42c0.56-0.28,1.05-0.62,1.45-1.03c0.4-0.4,0.7-0.82,0.9-1.27
			c0.2-0.45,0.3-0.84,0.3-1.17c0-0.8-0.43-1.78-1.3-2.94c-0.86-1.15-1.83-2.33-2.89-3.54c-1.06-1.2-2.03-2.35-2.89-3.43
			c-0.87-1.08-1.3-1.98-1.3-2.69v-0.78c0.26-0.8,0.66-1.63,1.2-2.48c0.53-0.85,1.13-1.63,1.8-2.33c0.66-0.71,1.4-1.33,2.19-1.87
			c0.8-0.54,1.66-0.88,2.59-1.03c0.46-0.19,0.95-0.5,1.45-0.92c0.5-0.42,0.95-0.82,1.35-1.2c0.53-0.38,1.01-0.74,1.45-1.1
			c0.43-0.35,0.88-0.58,1.35-0.67c0.53-0.33,1.36-0.52,2.49-0.57c1.13-0.05,1.96-0.07,2.49-0.07H358.51z"/>
            </svg>
                    </div>
                    
                    <div className="maskContent" > 
						


                        <div id="m1" className="menuText1" onClick={()=>this.handleClickHome()} value="Click me">
						<NavLink className="Nav_link"  to="/home">
				 			Home
						</NavLink>
                        </div> 
						<div id="m2" className="menuText2" onClick={()=>this.handleClickUnit()} value="Click me">
						<NavLink className="Nav_link"  to="/unit">
				 			Unit
						</NavLink>
                        </div> 
						<div id="m3" className="menuText3" onClick={()=>this.handleClickKontakt()} value="Click me">
						<NavLink className="Nav_link" activeClassName="activeRoute" to="/contact">
							Contact
						</NavLink>
                        </div> 
						<div   >
                            <svg  >
                                <clipPath id="clip"  > 
                                    <path d="M0.08-0.04v200.05H400V-0.04H0.08z M258.59,131.18c-5.06,9.16-12.37,16.28-21.93,21.38
		c-9.56,5.09-21.48,7.64-35.74,7.64c-14.5,0-26.5-2.2-36.01-6.6c-9.5-4.4-17.21-11.36-23.11-20.88c-5.91-9.52-8.86-21.43-8.86-35.74
		c0-19.99,5.85-35.55,17.54-46.68c11.7-11.13,27.98-16.7,48.86-16.7c21.4,0,37.89,5.47,49.47,16.41
		c11.58,10.94,17.37,26.26,17.37,45.97C266.18,110.29,263.65,122.02,258.59,131.18z"/>
                                 </clipPath>
                            </svg>
                    </div>
                    </div>
					
                   
            	</div>
			 </div>
		
        )
    }
}

export default menu
