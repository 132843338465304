import React, { Component } from 'react'
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';

class graph4 extends Component {
    
    constructor(props) {
        super(props)
        this.state = {
             dataload: 0,
             some: '',
             tickBegin: 0,
             tickEnd: 0,
             ticksAmount: props.ticks,
             ticksStart: props.ticksStart,
             tickdiv: props.tickdiv,
             unitid: props.unitid,
             dataid: props.dataid,
             dataidA: props.dataidA, 
             dataidB: props.dataidB, 
             dataidC: props.dataidC,
             dataidD: props.dataidD,  
             dataidAname : props.AName,
             dataidBname : props.BName,
             dataidCname : props.CName,
             dataidDname : props.DName
        }
        this.NameA = props.AName;
        this.NameB = props.BName;
        this.NameC = props.CName;
        this.NameD = props.DName; 
    }
    componentDidMount() {
        this.fetchData();
        this.timeInterval = setInterval(() => {
            this.fetchData();
            console.log("Timer")
          },60000)
      }
    componentWillUnmount(){
          clearInterval(this.timeInterval)
    }
    CallFromParent(updateId,ticksInput){
        console.log("CallFrom")
        console.log(ticksInput)
        if(updateId == 1 ){
            this.state.ticksAmount = ticksInput;
        }
        if(updateId == 2 ){
            this.state.ticksStart = ticksInput;
        }
        if(updateId == 3 ){
            this.state.tickdiv = ticksInput;
        }
        if(updateId == 4){
            this.state.ticksStart = this.state.tickBegin;
        }
        if(updateId == 5){
            this.state.ticksStart = this.state.tickEnd + this.state.ticksAmount
        }
        this.fetchData();
      }

    fetchData = function(){
        //getGraph5DataDb?unitid=1000&dataidA=1&dataidB=2&dataidC=3&dataidD=4&dataidE=5&ticks=100&ticksStart=38000
        //this.urls = "http://192.168.1.17:3000/getGraph5DataDb?"
        this.urls = "https://sdlab.dk/getGraph5DataDb?"
            + "unitid="  + this.state.unitid
            + "&dataidA=" + this.state.dataidA 
            + "&dataidB=" + this.state.dataidB
            + "&dataidC=" + this.state.dataidC
            + "&dataidD=" + this.state.dataidD
            + "&dataidE=" + this.state.dataidD
            + "&ticks="  + this.state.ticksAmount
            + "&tickdiv="  + this.state.tickdiv
            + "&ticksStart=" + this.state.ticksStart 
        return fetch(this.urls)
        .then((response) => response.json())
        .then((data) => {
          console.log(data);
          console.log('BBBBB');
          this.state.tickEnd = data[0].tick;
          this.data = data.reverse();
          this.state.tickBegin = data[0].tick;
          console.log(this.state.tickBegin);
          console.log(this.state.tickEnd);
          this.setState({
                dataload: 1
            }
          )
        });
      }

      
    
    render() {
        return (
            <div>
                <div>
                    <LineChart
                    
                    width={800}
                    height={160}
                    data={this.data}
                    margin={{
                        top: 20,
                        right: 20,
                        left: 0,
                        bottom: 20
                    }}
                >
                    <CartesianGrid vertical={false} stroke='#EAF0F4'/>
                    <XAxis dataKey="tick" tick={{fontSize: 6}} stroke="#474440" />
                    <YAxis tickCount={10} tick={{fontSize: 8}}  domain={[0, 500]} interval={1} stroke="#474440"  />
                    <Tooltip wrapperStyle={{ fontSize: 8 }} YAxis={10}/>
                    <Line type="monotone" dataKey="dataA" stroke="#474440" name={this.NameA} dot={{ r: 1 }}  activeDot={{ r: 4 }}  />
                    <Line type="monotone" dataKey="dataB" stroke="#9f6939" name={this.NameB} dot={{ r: 1 }}  activeDot={{ r: 4 }}  />
                    <Line type="monotone" dataKey="dataC" stroke="#5b6617" name={this.NameC} dot={{ r: 1 }}  activeDot={{ r: 4 }}  />
                    <Line type="monotone" dataKey="dataD" stroke="#8caec0" name={this.NameD} dot={{ r: 1 }}  activeDot={{ r: 4 }}  />
                </LineChart>
                </div>
            </div>
        )
    }
}

export default graph4
